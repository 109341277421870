@use "variables";


.btn, button, .tag, #filterwrapper > a,
input[type='button'],
input[type='reset'],
input[type='submit'] {
  background: variables.$consoleBackground;
  font-family: variables.$consoleFont;
  padding: .5rem;
  color: variables.$consoleText;
  margin: auto auto 1.5rem;
  line-height: initial;
  border: none;

  &:focus, &:hover, &:active {
    outline: variables.$consoleBackground solid 1px;
    background-color: white;
    color: variables.$textColor;
  }

  &:active {
    color: variables.$consoleBlue;
  }
}

button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
  cursor: pointer;
}

