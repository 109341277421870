@use "variables";

.container {
  max-width: 1000px;
  position: relative;
  width: 100%;
  background-color: white;
  border: 1px solid variables.$consoleBackground;
  text-align: center;
  margin: 2rem auto 2rem;

  @media (max-width: 1000px) {
    border: none;
    margin-top: 0;
    margin-bottom: 0;
  }
}
