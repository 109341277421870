@use "variables";

.contact {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  svg {
    width: 45px;
    height: 45px;
    color: variables.$consoleBackground;
    transition: color .2s;
    padding: 5px;

    &.keybase .line {
      fill: currentColor;
    }
  }

  a:hover svg {
    color: variables.$consoleFocus;
    outline: 1px solid variables.$consoleFocus;
  }
}
