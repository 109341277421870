.topbuttons {
  @media screen and (max-width: 480px) {
    display: flex;
  justify-content: center;
  }
}


.languageSelector, .backButton {
  position: absolute;
  top: 0;
  z-index: 10000;
  margin: 0;

  @media screen and (max-width: 480px) {
    position: initial;
    display: block;
    width: 50%;
    border: 1px solid white;
  }
}

.languageSelector {
  right: 0;
}

.backButton {
  left: 0;

  .arrow {
    font-weight: bold;
  }
}
