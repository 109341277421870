@use "variables";

.intro {
  padding-top: 2rem;
}

.introduction {
  text-align: left;
  margin-bottom: 10px;
}

h1 {
  font-family: variables.$consoleFont;
  color: black !important;
  cursor: initial !important;

  div {
    font-size: 24px;
    line-height: 28px;
  }
}

@media screen and (max-width: 480px) {
  h1 {
    text-align: left;
    padding: 0 10px;

  }
  .languageSelector {
    right: 10px;
  }
}
