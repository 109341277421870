.leaf {
  cursor: pointer;

  svg {
    z-index: 10000;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate3d(0, 0, 0);
    height: auto;
  }
}
