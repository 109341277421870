#filterwrapper, #searchwrapper, .contact,.introduction,#imprint {
  margin: 0 2rem;
}

#blockwrapper{
  margin: 2rem;
}

.gpg{
  @media (max-width: 1000px) {
    padding-bottom: 2rem;
  }
}


.button,
button,
input,
textarea,
select,
fieldset {
  margin-bottom: 1rem
}

pre,
blockquote,
dl,
figure,
table,
p,
ul,
ol {
  margin-bottom: 1.5rem
}

p + ul {
  margin-top: -1rem;
}

.simplePageHeading {
  padding-top: 2rem;
}
