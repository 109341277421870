@use "variables";


html {
  font-size: 62.5%;
}

body {
  color: variables.$textColor;
  font-size: 1.8em;
  font-weight: normal;
  font-family: variables.$fontFamily;
  line-height: 1.6;
}

p {
  margin-top: 0;
  //hyphens: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
  //letter-spacing: -.1rem;
  margin-bottom: 2.0rem;
  margin-top: 0;
}

h1 {
  font-size: 4.0rem;
  line-height: 1.2;
  text-align: center;
  text-wrap: balance;
}

h2 {
  font-size: 3.6rem;
  line-height: 1.25;
}

h3 {
  font-size: 3.0rem;
  line-height: 1.3;
}

h4 {
  font-size: 2.4rem;
  letter-spacing: -.08rem;
  line-height: 1.35;
}

h5 {
  font-size: 1.8rem;
  letter-spacing: -.05rem;
  line-height: 1.5;
}

h6 {
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 1.4;
}


.texttt {
  font-family: variables.$consoleFont;
}

pre {
  white-space: pre-wrap;
}

blockquote {
  border-left: .3rem solid variables.$gray;
  margin-left: 0;
  margin-right: 0;
  padding: 1rem 1.5rem;

  *:last-child {
    margin-bottom: 0
  }
}

a {
  color: variables.$linkColor;
  text-decoration: none;

}

a, .btn, button, .tag {
  transition: background-color 0.2s ease-in-out, color 0.2s, border-color 0.2s
}


table {
  border-spacing: 0;
  width: 100%;
}

th,
td {
  border-bottom: .1rem solid #e1e1e1;
  padding: 1.2rem 1.5rem;
  text-align: left;

  &:first-child {
    padding-left: 0
  }

  &:last-child {
    padding-right: 0
  }
}
