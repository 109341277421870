.license{
  svg {
  color: black;

  path {
	fill: white;
	stroke: currentColor;
	stroke-width: 15.349;
	stroke-linecap: round;
  }

  text {
	font-family: sans-serif;
	fill: currentColor;
	text-align: start;
	line-height: 125%;
	font-weight: 700;
	font-size: 124.104px
  }
}

.MIT text {
  font-size: 124.104px;
}

.GPL text {
  font-size: 109.871px;
}

}
