@use "variables";

#blockwrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.card {
  color: variables.$consoleText;
  background: variables.$consoleBackground;
  transition: color .2s;
  z-index: 3;
  position: relative;
  margin-bottom: 20px;
  width: 100%;
  max-width: 300px;
  cursor: pointer;

  &:hover, &:focus {
    color: variables.$consoleFocus;

    .tag {
      color: variables.$consoleFocus;
      border-color: variables.$consoleFocus;
    }
  }


  img {
    display: block;
    height: auto;
    z-index: 5;
    position: relative;
  }

  .imgwrapper {
    height: 150px;
    width: 300px;
  }

  .textwrapper {
    height: 150px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;

    .title{
      text-wrap: balance;
    }

    > div {
      padding: 0 10px
    }
  }
}

.tag {
  display: inline-block;
  border: 1px variables.$consoleText solid;
  text-align: center;
  border-radius: variables.$borderRadius;
  margin: 0 2px;
}

.tag, #filterwrapper > a {
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 1;
  padding: .3em .6em .3em;

}

#filterwrapper {
  margin-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  a {
    display: block;
    color: variables.$consoleBackground;
    background: white;

    &.active {
      color: variables.$consoleWhite !important;
      outline: none !important;
      background-color: variables.$consoleBackground;
    }

    &:focus, &:active, &:hover {
      color: variables.$consoleBackground;
      outline: 1px solid variables.$consoleBackground;
    }
  }
}

#searchwrapper {
  margin-bottom: 2rem;

  input {
    font-family: variables.$consoleFont;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: .5rem;
    border: 1px solid variables.$consoleBackground;
    border-radius: variables.$borderRadius;
    margin-bottom: 0;
    background-color: white;
    color: variables.$consoleBackground;
    max-width: 300px;
    width: 100%;
    transition: border-color .2s, color .2s;

    &:focus {
      border-color: variables.$consoleBlue;
      outline: none;

      &::placeholder {
        color: variables.$consoleBlue;
        opacity: .5;
      }

    }

    &::placeholder {
      color: variables.$consoleBackground;
      transition: color .2s;
      opacity: 1;
    }

    &::selection {
      background: variables.$consoleBackground;
      color: variables.$consoleWhite;
    }
  }
}


.gpg {
  display: block;
  font-family: monospace;
  font-size: 80%;
}

#visitcounter {
  height: 35px;
  width: auto;
}

