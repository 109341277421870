@use "variables";


.imagewrapper {
  //padding-bottom: 50%;
  position: relative;
  background: white;

  &.separator {
    border-bottom: solid 1px #ddd;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
    position: relative;
    z-index: 1100;
  }

}

canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}


.modal-body {
  padding: 20px 30px;
  margin: 20px 0;
  text-align: left;
}


.modal-linkbar {
  display: flex;
  justify-content: space-around;

  &.try {
    margin-top: 40px;
  }

  a {
    position: relative;
    padding: 16px;

    > svg {
      width: 36px;
      height: 36px;
      display: block;
      color: black;
    }

    svg, span {
      transition: .2s;
    }

    display: block;

    &:hover {
      svg, .try-it-out {
        color: variables.$linkColor;
      }
    }

    .try-it-out {
      position: absolute;
      top: -40px;
      left: 23px;
      right: -150px;
      vertical-align: top;
      color: black;

      svg {
        display: inline-block;
        position: relative;
        top: 20px;
      }
    }
  }
}

iframe {
  width: 100%;
  height: 400px;
  border: none;
  border-radius: variables.$borderRadius;
  display: block;
  margin-bottom: 15px;
}

#readmore {
  display: block;
}

