@use "variables";

.languagePicker {
  display: flex;
  justify-content: space-around;
  min-height: 70vh;
  align-items: center;

  a {
    display: block;
    background: variables.$consoleBackground;
    color: variables.$consoleText;
    padding: 2rem;

    &:active, &:focus, &:hover {
      color: variables.$consoleBlue;
    }
  }
}
